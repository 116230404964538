<template>
  <vs-sidebar
    click-not-close
    position-right
    parent="body"
    default-index="1"
    color="primary"
    class="add-new-data-sidebar items-no-padding"
    spacer
    v-model="isSidebarActiveLocal"
  >
    <div class="mt-6 flex items-center justify-between px-6">
      <h4>{{ isNewItemLocal ? "Novo" : "Alterar" }} item</h4>
      <feather-icon
        icon="XIcon"
        @click.stop="isSidebarActiveLocal = false"
        class="cursor-pointer"
      ></feather-icon>
    </div>
    <vs-divider class="mb-0"></vs-divider>

    <VuePerfectScrollbar
      class="scroll-area--data-list-add-new p-5"
      :settings="settings"
    >
      <form>
        <vs-input
          v-validate="'required'"
          name="name"
          class="w-full mb-6"
          label-placeholder="Nome Prato"
          v-model="name"
          :color="name ? 'success' : 'danger'"
        />

        <p>Tipo de prato:</p>
        <v-select
          :dir="$vs.rtl ? 'rtl' : 'ltr'"
          v-model="selected"
          :options="types"
        />
      </form>
    </VuePerfectScrollbar>

    <div class="flex flex-wrap items-center justify-center p-6" slot="footer">
      <vs-button class="mr-6" @click="submitAction">Salvar</vs-button>
      <vs-button
        type="border"
        color="danger"
        @click="isSidebarActiveLocal = false"
        >Cancelar</vs-button
      >
    </div>
  </vs-sidebar>
</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import vSelect from "vue-select";
import ggcsApi from "@/services/ggcs";

// 1 - comum, light, guarnição, sobremesa, sopa.
export default {
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true
    },
    isNewItem: {
      type: Boolean,
      required: true
    },
    editItemData: {
      type: Object,
      required: false
    }
  },
  data() {
    return {
      name: "",
      selected: { id: 1, label: "Comum" },
      types: [
        { id: 1, label: "Comum" },
        { id: 2, label: "Light" },
        { id: 3, label: "Guarnição" },
        { id: 4, label: "Sobremesa" },
        { id: 5, label: "Sopa" },
        { id: 6, label: "Salada" },
        { id: 7, label: "Especial" }
      ],
      settings: {
        // perfectscrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: 0.6
      }
    };
  },
  watch: {
    editItemData() {
      this.name = this.editItemData.name;
      this.selected = this.types.find(
        item => item.id == this.editItemData.type
      );
    },
    isNewItem(newVal) {
      if (newVal) {
        this.clearData();
      }
    }
  },
  computed: {
    validateForm() {
      return !this.errors.any() && this.name != "" && this.type < 0;
    },
    isSidebarActiveLocal: {
      get() {
        return this.isSidebarActive;
      },
      set(val) {
        if (!val) {
          this.$emit("closeSidebar");
        }
      }
    },
    isNewItemLocal: {
      get() {
        return this.isNewItem;
      }
    }
  },
  methods: {
    setDefaultValues() {
      this.finalDate = null;
      this.token = null;
      this.email = null;
    },
    submitAction() {
      this.renderLoading();
      if (this.isNewItemLocal) {
        this.createSubmit();
      } else {
        this.updateSubmit();
      }
    },
    createSubmit() {
      const data = { name: this.name, type: this.selected.id };
      const self = this;
      ggcsApi
        .post("buffets/plates", data)
        .then(res => {
          self.$vs.loading.close();
          const { data } = res;
          if (data.success) {
            self.notifySuccess();
            self.$emit("didAddItem");
          }


          self.isLoading = false;
          self.isSidebarActiveLocal = false;
          self.clearData();
        })
        .catch(() => {
          self.$vs.loading.close();
          // console.error("Error result: ", error);
        });
    },
    updateSubmit() {
      const id = this.editItemData.plate_id;
      const data = { name: this.name, type: this.selected.id };
      const self = this;
      ggcsApi
        .put(`buffets/plates/${id}`, data)
        .then(res => {
          self.$vs.loading.close();
          const { data } = res;
          if (data.success) {
            self.notifySuccess();
            self.$emit("didAddItem");
          }
          self.isLoading = false;
          self.isSidebarActiveLocal = false;
          self.clearData();
        })
        .catch(() => {
          self.$vs.loading.close();
          // console.error("Error result: ", error);
        });
    },
    clearData() {
      this.name = "";
      this.selected = { id: 1, label: "Comum" };
    },
    renderLoading() {
      this.$vs.loading({
        // container: "#items-list-view",
        scale: 0.45
      });
    },
    notifySuccess() {
      const text = this.isNewItemLocal ? "adicionado" : "alterado";
      this.$vs.notify({
        title: "Sucesso",
        text: `Item ${text} com sucesso`,
        color: "success",
        iconPack: "feather",
        icon: "icon-check",
        position: "top-right"
      });
    }
  },
  components: {
    VuePerfectScrollbar,
    vSelect
  }
};
</script>

<style lang="scss" scoped>
.add-new-data-sidebar {
  /deep/ .vs-sidebar--background {
    z-index: 52010;
  }

  /deep/ .vs-sidebar {
    z-index: 52010;
    width: 500px;
    max-width: 90vw;
  }
}

.scroll-area--data-list-add-new {
  height: calc(100% - 4.3rem);
}
</style>
