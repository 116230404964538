<template>
  <div
    id="items-list-view"
    class="items-list-container relative overflow-hidden"
  >
    <item-page
      v-on:didAddItem="didAddItem"
      :isSidebarActive="ItemPage"
      :isNewItem="isNewItem"
      :editItemData="editItemData"
      @closeSidebar="ItemPage = false"
    />
    <vs-table
      ref="table"
      v-model="selected"
      pagination
      :max-items="itemsPerPage"
      search
      :data="items"
    >
      <div
        slot="header"
        class="flex flex-wrap-reverse items-center flex-grow justify-between"
      >
        <div class="flex flex-wrap-reverse items-center">
          <div class="p-3 mb-4 mr-4">
            <!-- ADD NEW -->
            <div
              class="p-3 mb-4 mr-4 rounded-lg cursor-pointer flex items-center justify-between text-lg font-medium text-base text-primary border border-solid border-primary"
              @click="
                ItemPage = true;
                isNewItem = true;
              "
            >
              <feather-icon icon="PlusIcon" svgClasses="h-4 w-4" />
              <span class="ml-2 text-base text-primary">Novo Item</span>
            </div>
          </div>
        </div>

        <!-- ITEMS PER PAGE -->
        <vs-dropdown vs-trigger-click class="cursor-pointer mb-4 mr-4">
          <div
            class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
          >
            <span class="mr-2"
              >{{ currentPage * itemsPerPage - (itemsPerPage - 1) }} -
              {{
                items.length - currentPage * itemsPerPage > 0
                  ? currentPage * itemsPerPage
                  : items.length
              }}
              de {{ items.length }}</span
            >
            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
          </div>
          <!-- <vs-button class="btn-drop" type="line" color="primary" icon-pack="feather" icon="icon-chevron-down"></vs-button> -->
          <vs-dropdown-menu>
            <vs-dropdown-item @click="itemsPerPage = 4">
              <span>4</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage = 10">
              <span>10</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage = 15">
              <span>15</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage = 20">
              <span>20</span>
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
      </div>
      <template slot="thead">
        <vs-th sort-key="name">Nome</vs-th>
        <vs-th sort-key="type">Tipo</vs-th>
        <!-- <vs-th sort-key="created_at">Criado em:</vs-th> -->
        <vs-th>Ações</vs-th>
      </template>

      <template slot-scope="{ data }">
        <tbody>
          <vs-tr :key="indextr" v-for="(tr, indextr) in data">
            <vs-td>
              <p class="product-name font-medium">{{ tr.name }}</p>
            </vs-td>
            <vs-td>
              {{ formatType(tr.type) }}
            </vs-td>
            <vs-td>
              <div class="flex">
                <vs-button
                  type="border"
                  size="small"
                  icon-pack="feather"
                  icon="icon-edit"
                  @click="editItem(tr.plate_id)"
                  color="warning"
                  class="mr-1"
                ></vs-button>
                <vs-button
                  type="border"
                  size="small"
                  icon-pack="feather"
                  icon="icon-trash"
                  @click="removeItem(tr.plate_id)"
                  color="danger"
                ></vs-button>
              </div>
            </vs-td>
          </vs-tr>
        </tbody>
      </template>
    </vs-table>
  </div>
</template>
<script>
import ItemPage from "./Page.vue";
import ggcsApi from "@/services/ggcs";

export default {
  data() {
    return {
      editItemData: {},
      itemsPerPage: 20,
      isMounted: false,
      editIndex: null,
      ItemPage: false,
      isNewItem: true,
      items: []
    };
  },
  computed: {
    currentPage() {
      if (this.isMounted) {
        return this.$refs.table.currentx;
      }
      return 0;
    }
  },
  methods: {
    renderLoading() {
      this.$vs.loading({
        // container: "#items-list-view",
        scale: 0.45
      });
    },
    formatDateTime(datetime) {
      return (
        datetime.toLocaleDateString() + " " + datetime.toLocaleTimeString()
      );
    },
    formatData(doc) {
      let data = doc.data();
      data["id"] = doc.id;
      let dataFormat = data;
      return dataFormat;
    },
    formatType(id) {
      const types = [
        { id: 1, label: "Comum" },
        { id: 2, label: "Light" },
        { id: 3, label: "Guarnição" },
        { id: 4, label: "Sobremesa" },
        { id: 5, label: "Sopa" },
        { id: 6, label: "Salada" },
        { id: 7, label: "Especial" }
      ];
      return types.find(type => type.id == id).label;
    },
    fetch() {
      this.renderLoading();
      this.editIndex = null;
      const self = this;
      ggcsApi
        .get("buffets/plates")
        .then(res => {
          self.$vs.loading.close();
          const { data } = res;
          if (data.success) {
            if (data.result) {
              self.items = data.result;
            }
          }
          self.isLoading = false;
        })
        .catch(() => {
          self.$vs.loading.close();
        });
    },
    removeItem(id) {
      const index = this.items.findIndex(item => {
        return item.plate_id === id;
      });

      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: `Remover Item`,
        text: "Você tem certeza em remover este item ?",
        accept: this.acceptRemoveItem,
        acceptText: "Remover",
        cancelText: "Cancelar",
        parameters: index
      });
    },
    editItem(id) {
      const index = this.items.findIndex(item => {
        return item.plate_id === id;
      });

      this.editItemData = {};
      this.isNewItem = false;
      this.ItemPage = true;
      this.editItemData = this.items[index];
    },
    acceptRemoveItem(index) {
      const self = this;
      const id = this.items[index].plate_id;

      ggcsApi
        .delete(`buffets/plates/${id}`)
        .then(res => {
          self.$vs.loading.close();
          const { data } = res;
          if (data.success) {
            self.items.splice(index, 1);
            self.$vs.notify({
              title: "Sucesso",
              text: "Item removido",
              color: "success",
              iconPack: "feather",
              icon: "icon-check",
              position: "top-right"
            });
          }
        })
        .catch(() => {
          self.$vs.loading.close();
        });
    },
    didAddItem() {
      //this.access.unshift(document);
      this.fetch();
    }
  },
  created() {
    this.fetch();
  },
  components: {
    ItemPage
    // EditEvent
  }
};
</script>
